<template>
    <b-container fluid>
        <b-row class="text-left z-background-blue text-light">
            <b-col>
                <b-container>
                    <b-row class="py-5">
                        <b-col>
                            <h1 class="z-text-bold z-text-bigger">
                                Desarrolladores
                            </h1>
                            <p class="m-0">
                                Conoce cada detalle de la plataforma
                            </p>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <b-row class="api-top-cta">
            <a class="anchor" id="api-integration" href="#"></a>
            <b-col>
                <b-container class="pt-5 text-center">
                    <b-row>
                        <b-col cols="12" class="pt-xl-5">
                            <h2 class="z-text-bigger z-text-bold">
                                Integración de APIs con Zubut
                            </h2>
                            <p class="z-text-medium">
                                Fácil y sencilla, además podemos adecuarnos a tus necesidades.
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="mx-auto py-5">
                        <b-col>
                            <a href="https://api.zubut.com/doc/" target="_blank">
                                <z-button style="width: auto;" variant="secondary">
                                    Ver documentación de API
                                </z-button>
                            </a>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <img
                                class="img-fluid api-integration"
                                src="@/assets/img/api-integration.png"
                                alt="cotizar"
                            />
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <b-row class="api-benefits text-xl-left">
            <b-col
                cols="12"
                md="6"
                class="api-benefit py-5 px-4 p-xl-5 z-background-light-blue z-text-white"
            >
                <h3 class="z-text-bold mb-5">
                    Ahorra tiempo y simplifica tus procesos, realizando la integración de tu sistema
                    con el nuestro.
                </h3>
                <p class="z-text-normal">
                    Comunicarse con Zubut es muy rápido y sencillo. Por medio de llamadas a nuestros
                    servicios web (REST) usando JSON es muy fácil lograr una comunicación exitosa.
                </p>
            </b-col>
            <b-col
                cols="12"
                md="6"
                class="api-benefit py-5 px-4 p-xl-5 z-background-blue z-text-white"
            >
                <h3 class="z-text-bold mb-5">
                    ¿Necesitas adecuar la integración o tienes requisitos muy específicos?
                </h3>
                <p class="z-text-normal">
                    No te preocupes, nuestro equipo de desarrolladores puede apoyarte y realizar las
                    modificaciones necesarias para una integración exitosa y personalizada.
                </p>
            </b-col>
        </b-row>
        <b-row
            class="z-background-light-grey px-4 py-5 p-lg-5 api-bottom-cta d-xl-flex align-content-xl-end"
        >
            <b-col cols="12" class="supported-tech mb-xl-5">
                <h3 class="font-weight-bold">
                    ¿Usas Wordpress, Joomla o algún framework parecido?
                </h3>
                <p>Zubut cuenta con integración para varios frameworks</p>
                <img
                    class="d-none d-md-block platform-logo wordpress"
                    src="@/assets/icons/wordpress.svg"
                    alt="wordpress"
                    width="100"
                />
                <img
                    class="d-none d-md-block platform-logo joomla"
                    src="@/assets/img/joomla.png"
                    alt="joomla"
                    width="100"
                />
            </b-col>
            <b-col cols="12">
                <a href="https://api.zubut.com/doc/" target="_blank">
                    <z-button class="w-auto" variant="secondary">
                        Ver documentación de API
                    </z-button>
                </a>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "ForDevelopers",
    metaInfo: {
        title: "Para desarrolladores",
        meta: [
            {
                name: "description",
                content: `Integración de APIs con Zubut. Fácil y sencilla, además podemos adecuarnos a tus necesidades.`
            }
        ]
    }
};
</script>

<style lang="scss" scoped>
.platform-logo {
    position: absolute;
    opacity: 0.1;

    &.joomla {
        right: 0px;
        top: -50px;
    }
}

@media only screen and (min-width: 992px) {
    .api-integration {
        max-width: 680px;
    }
}

@media only screen and (min-width: 1200px) {
    .api-benefits {
        position: absolute;
        left: 50%;
        margin-left: -580px;
        margin-top: -340px;
        width: 1160px;

        .api-benefit {
            padding: 80px !important;
        }
    }

    .api-top-cta {
        padding-bottom: 340px;
    }

    .api-bottom-cta {
        height: 456px;
        padding-bottom: 80px !important;
    }

    .platform-logo {
        &.wordpress {
            bottom: -180px;
            left: 50%;
            margin-left: -400px;
        }

        &.joomla {
            right: 100px;
            top: -80px;
        }
    }
}
</style>
